/**
 * Go to Pro dialog
 */

import React from 'react';
import {Dialog, DialogTitle, DialogContent, Button, Typography, IconButton, Divider} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import successImage from '../../images/success.png'
import {Progress} from '../wigets/stripe-progress'
import {navigate} from 'gatsby'

const GotoProDialog = ({open, handleClose}) => {

    const [state, setState]=React.useState({backDropOpen:false})

    const goPro = () =>{
        navigate('/upgrade')
    }

    return(
        <div>
            <Progress open={state.backDropOpen} />
            <Dialog onClose={handleClose} open={open} >
                <DialogTitle className='text-center p-0' 
                    style={{minWidth:300, width: '30vw'}}>
                    <IconButton
                        className='float-left'
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize='large'/>
                    </IconButton>
                    <Typography className='b-7 pt-3 mr-5'>
                        To use this feature, go Pro
                    </Typography>
                </DialogTitle>
                <Divider/>
                <DialogContent
                    className='text-center'
                >
                    <img src={successImage} className='mt-4' alt='Success'/>
                    <Typography style={{color:'grey'}}>$3.99/mo</Typography>
                    <Typography>Custom pin codes</Typography>
                    <Typography>Share to your location</Typography>
                    <Typography>Share files larger than 200MB</Typography>
                    <Typography> Password protected sharing</Typography>
                    <Button
                        onClick={goPro}
                        variant='contained'
                        className='w-100 my-3'
                        style={{backgroundColor:'rgb(232, 87, 58)', color:'white'}}
                    >
                        GO PRO
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
            
    )
};

export default GotoProDialog;
