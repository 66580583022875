import React from "react"
import Modal from "react-bootstrap/Modal"
import { FiPaperclip } from "react-icons/fi"
import { IoMdClose } from "react-icons/io"
import { FaTrashAlt } from "react-icons/fa"
import Question from "../images/question.png"
import firebase from "./auth/fire-base.js"
import geohash from "ngeohash"
import { ReactTinyLink } from "react-tiny-link"
import ReactPlayer from "react-player"
import FileIcon from "../images/file-icon.png"
import Dropdown from "react-bootstrap/Dropdown"
import GotoProDialog from './dialogs/goto-pro-dialog'
import Auth from '../Auth'
import {NotificationContainer, NotificationManager} from 'react-notifications';

const auth = new Auth();

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <a
    className="stream-add-btn"
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
  >
    Add to Stream
  </a>
))

const CustomToggle2 = React.forwardRef(({ onClick }, ref) => (
  <a
    className="stream-info-btn"
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <img src={Question} />
  </a>
))

class NewPinModal extends React.Component {
  constructor(props) {
    super(props)
    this.handlePinIDChange = this.handlePinIDChange.bind(this)
    this.onPost = this.onPost.bind(this)
    this.storeLocation = this.storeLocation.bind(this)
    this.handleTextChange = this.handleTextChange.bind(this)
    this.handleFileClick = this.handleFileClick.bind(this)
    this.pinError = React.createRef()
    this.pinPost = React.createRef()
    this.pinText = React.createRef()
    this.password = React.createRef()
    this.fileSelector = React.createRef()
    this.showErrorMessage = React.createRef()
    this.streamInputChange = this.streamInputChange.bind(this)
    this.streamInputSubmit = this.streamInputSubmit.bind(this)
    this.closeGotoProDialog = this.closeGotoProDialog.bind(this);
    this.gotoPro = this.gotoPro.bind(this);
  }

  state = {
    pin_id: "",
    current_username: "",
    lat: null,
    long: null,
    geohash: null,
    additionalPostHTML: [],
    image: null,
    fileurl: "",
    progress: 0,
    pinStream: null,
    pic: "",
    name: "",
    errorMessage: "",
    uploading: false,
    streamNameError: true,
    user:null,
    showGotoProdialog:false,
  }

  getUserStreams() {
    if (firebase.auth().currentUser) {
      firebase
        .firestore()
        .collection("streams")
        .where(
          "creator",
          "==",
          firebase
            .firestore()
            .collection("users")
            .doc(firebase.auth().currentUser.uid)
        )
        .orderBy("createdAt", "desc")
        .onSnapshot(snapshot => {
          this.setState({ streams: [] })
          snapshot.forEach(doc => {
            if (doc && doc.data()) {
              this.setState(prevState => {
                const streams = this.state.streams.concat({
                  id: doc.id,
                  name: doc.data().name,
                  createdAt: doc.data().createdAt,
                })

                return { streams }
              })
            }
          })
        })
    }
  }

  async componentWillMount() {
    const user = await auth.user();
    this.setState({user: user})
    this.isLoggedIn()
  }

  componentWillReceiveProps() {
    this.isLoggedIn()
  }

  async isLoggedIn() {
    await firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.getUserStreams()
      }
    })
  }

  getLocation() {
    if (!this.state.lat || !this.state.long || !this.state.geohash) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.storeLocation)
      } else {
        alert("Geolocation is not supported by this browser.")
      }
    }
  }

  storeLocation(position) {
    this.setState({
      lat: position.coords.latitude,
      long: position.coords.longitude,
      geohash: geohash.encode(
        position.coords.latitude,
        position.coords.longitude
      ),
    })
  }

  componentDidMount() {
    Date.prototype.addHours = function(h) {
      this.setTime(this.getTime() + h * 60 * 60 * 1000)
      return this
    }

    this.getLocation()

    this.generatePinID()

    this.getUserStreams()

    this.setState({
      image: null,
      fileurl: this.props.fileURL ? this.props.fileURL : "",
      progress: 0,
      pinStream: null,
    })
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase
          .firestore()
          .collection("users")
          .doc(firebase.auth().currentUser.uid)
          .get()
          .then(doc => {
            this.setState({
              current_username: doc.data().username,
              name: doc.data().name,
              pic: doc.data().displayPic,
            })
          })
      }
    })
  }

  handlePinIDChange = ({ currentTarget: input }) => {
    this.setState(
      {
        pin_id: input.value,
      },
      () =>
        firebase
          .firestore()
          .collection("pins")
          .doc(this.state.current_username + "-" + input.value)
          .get()
          .then(docSnapshot => {
            if (docSnapshot.exists) {
              if (this.props.editMode == false) {
                this.pinError.current.hidden = false
                this.pinPost.current.disabled = true
                this.pinPost.current.style.backgroundColor = "#f0917f"
              }
            } else {
              this.pinError.current.hidden = true
              this.pinPost.current.disabled = false
              this.pinPost.current.style.backgroundColor = "#e8573a"
            }
          })
    )
  }

  getExpiry = () => {
    var e = document.getElementById("pin-time")
    var hours = parseInt(e.options[e.selectedIndex].value)

    if (hours == -1) {
      return null
    } else {
      return new Date().addHours(hours)
    }
  }

  onPost = () => {
    if (
      (this.pinText.current.value.trim() !== "" || this.state.fileurl !== "") &&
      !this.password.current.hidden &&
      this.password.current.value.trim() !== ""
    ) {
      this.processPin()
    } else if (
      (this.pinText.current.value.trim() !== "" || this.state.fileurl !== "") &&
      this.password.current.hidden
    ) {
      this.processPin()
    } else {
      this.showErrorMessage.current.hidden = false
      var error = ""
      if (
        this.pinText.current.value.trim() === "" &&
        this.state.fileurl === "" &&
        !this.password.current.hidden &&
        this.password.current.value.trim() === ""
      ) {
        error =
          "Please enter or upload content, then choose a password or uncheck require password"
      } else if (
        this.pinText.current.value.trim() === "" &&
        this.state.fileurl === ""
      ) {
        error = "Please enter or upload content above."
      } else {
        error = "Please choose a password or uncheck require password."
      }

      this.setState({ errorMessage: error })
      setTimeout(() => {
        this.showErrorMessage.current.hidden = true
      }, 3000)
    }
  }

  processPin() {
    let data = {
      lat: this.state.lat,
      long: this.state.long,
      geohash: this.state.geohash,
      expiry: this.getExpiry(),
      content: {
        text: this.pinText.current.value,
        file: this.state.fileurl,
      },
      stream: this.state.pinStream
        ? firebase
            .firestore()
            .collection("streams")
            .doc(this.state.pinStream)
        : null,
      createdAt: new Date(),
      creator: firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser.uid),
      pincode:
        this.props.editMode === false
          ? this.state.current_username + "-" + this.state.pin_id
          : this.props.storedPinCode.substr(1),
    }

    if (this.password.current !== undefined)
      if (!this.password.current.hidden)
        data["password"] = this.password.current.value

    var pincode = this.state.pin_id
    firebase
      .firestore()
      .collection("pins")
      .doc(
        this.props.editMode === false
          ? this.state.current_username + "-" + this.state.pin_id
          : this.props.storedPinCode.substr(1)
      )
      .set(data)
      .then(() => {
        if (!this.props.editMode) {
          firebase.analytics().logEvent("New Pin Created", { name: "New Pin" })
        }
        this.setState(
          {
            pin_id: "",
          },
          () => {
            this.props.hideModal()
            if (this.props.editMode === false) {
              window.location.href =
                "/search?pincode=" + this.state.current_username + "-" + pincode
            }
          }
        )
      })
  }

  generatePinID() {
    var pin_id = ""
    const chars = "abcdefghijklmnopqrstuvwxyz1234567890"

    for (let x = 0; x < 6; x++) {
      let i = Math.floor(Math.random() * 36)
      pin_id += chars.charAt(i)
    }

    firebase
      .firestore()
      .collection("pins")
      .doc(pin_id)
      .get()
      .then(docSnapshot => {
        if (docSnapshot.exists) {
          this.generatePinID()
        } else {
          this.setState({
            pin_id: pin_id,
          })
        }
      })
  }

  streamInputChange = event => {
    let streamName = document.getElementById("streamInput").value

    if (streamName) {
      firebase
        .firestore()
        .collection("streams")
        .doc(streamName)
        .get()
        .then(docSnapshot => {
          if (docSnapshot.exists) {
            this.setState({
              streamNameError: false,
            })
          } else {
            this.setState({
              streamNameError: true,
            })
          }
        })
    }
  }

  streamInputSubmit = event => {
    event.preventDefault()

    let streamName = document.getElementById("streamInput").value

    if (streamName) {
      firebase
        .firestore()
        .collection("streams")
        .doc(streamName)
        .get()
        .then(docSnapshot => {
          if (docSnapshot.exists) {
            this.setState({
              streamNameError: false,
            })
          } else {
            this.setState({
              streamNameError: true,
            })

            let data = {
              name: streamName,
              createdAt: new Date(),
              creator: firebase
                .firestore()
                .collection("users")
                .doc(firebase.auth().currentUser.uid),
            }

            firebase
              .firestore()
              .collection("streams")
              .doc(streamName)
              .set(data)
              .then(() => {
                this.setState({
                  pinStream: streamName,
                })
              })
          }
        })
    }
  }

  handleTextChange(event) {
    let pinText = event.target.value

    let geturl = new RegExp(
      // Strict regex to get only links with http
      "(^|[ \t\r\n])((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|aim|webcal):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))",
      "g"
      // "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?",
      // "g"
    )

    let all_urls = pinText.match(geturl)
    if (all_urls && all_urls.length > 0) {
      this.setState({ additionalPostHTML: all_urls })
    } else {
      this.setState({ additionalPostHTML: [] })
    }
  }

  imageError = e => {
    document.getElementById("file-image-div").hidden = true
    document.getElementById("file-download-div").hidden = false
  }

  handleFileClick = e => {
    if (e.target.files[0]) {
      const image = e.target.files[0]

      //Checking Image Size
      if((image.size > 50*1024*1024) && !this.state.user.pro){
          NotificationManager.error("Too much size, go to Pro")
          this.gotoPro();
      }else{
          this.setState({ image: image, fileurl: "", uploading: true }, () => {
            document.getElementById("file-image-div")
              ? (document.getElementById("file-image-div").hidden = false)
              : void 0
            document.getElementById("file-download-div")
              ? (document.getElementById("file-download-div").hidden = true)
              : void 0
            const { image } = this.state
            const uploadTask = firebase
              .storage()
              .ref(`files/${image.name}`)
              .put(image)
            uploadTask.on(
              "state_changed",
              snapshot => {
                // progress function ...
                const progress = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                )
                this.setState({ progress })
              },
              error => {
                // Error function ...
                console.log(error)
              },
              () => {
                // complete function ...
                firebase
                  .storage()
                  .ref("files")
                  .child(image.name)
                  .getDownloadURL()
                  .then(fileurl => {
                    this.setState({ fileurl: fileurl, uploading: false })
                  })
              }
            )
          })
      }
        
    }
  }

  deleteFile = () => {
    this.setState({
      fileurl: "",
      image: null,
      progress: 0,
    })
  }

  getStreamHTML = streamName => {
    return (
      <Dropdown.Item
        key={streamName}
        onClick={() =>
          this.setState({
            pinStream: streamName,
          })
        }
      >
        {streamName}
      </Dropdown.Item>
    )
  }

  toggle() {
    if(this.state.user && this.state.user.pro){
      var x = document.getElementById("password1")
      if (x.hidden === false) {
        x.hidden = true
      } else {
        x.hidden = false
      }
    }else{
      this.gotoPro()
    }
  }

  closeGotoProDialog = () =>{
      this.setState({showGotoProdialog:false})
  }

  gotoPro = () =>{
    if(!(this.state.user && this.state.user.pro)){
      this.setState({showGotoProdialog:true})
    }
  }

  render() {
  
    return (
      <Modal show={this.props.isOpen} onHide={this.props.hideModal}>
        <Modal.Header>
          <Modal.Title>
            <div className="row">
              <div className="col-md-12">
                <h6 className="new-pin-title text-center">
                  <IoMdClose
                    onClick={this.props.hideModal}
                    style={{ cursor: "pointer" }}
                  ></IoMdClose>
                  &nbsp;
                  {this.props.editMode === false
                    ? "Upload & Share"
                    : "Edit & Share"}
                </h6>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NotificationContainer />
          <GotoProDialog open={this.state.showGotoProdialog} handleClose={this.closeGotoProDialog} />
          <div className="row">
            <div className="col-md-8 col-7 pull-left white-bg">
              <span className="home-pin-wrapper" onClick={()=>{this.gotoPro()}}>
                {this.state.current_username}-
                <input
                  id="pinID"
                  name="pinID"
                  className="home_pinID"
                  style={{ width: "123px" }}
                  disabled={!(this.state.user&&this.state.user.pro)}
                  value={
                    this.props.editMode
                      ? this.props.storedPinCode.substr(1).split("-")[1]
                      : this.state.pin_id
                  }
                  type="text"
                  maxLength="6"
                  onChange={this.editMode ? null : this.handlePinIDChange}
                />
              </span>
              <p
                id="pinError"
                style={{ color: "red" }}
                hidden
                ref={this.pinError}
              >
                Error! Try a different pin ID
              </p>
            </div>

            <div
              className="col-md-4 col-5 pull-left"
              style={{ display: `inline-flex`, paddingLeft: "0px" }}
            >
              {this.props.editMode === false ? (
                this.state.pinStream ? (
                  <div className="stream-add-btn">{this.state.pinStream}</div>
                ) : (
                  <div>
                    <Dropdown
                      style={{
                        marginTop: "17px",
                        marginLeft: "5px",
                        float: "left",
                      }}
                    >
                      <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
                      <Dropdown.Menu>
                        <form onSubmit={this.streamInputSubmit}>
                          <div
                            style={{ display: "flex", flowDirection: "row" }}
                          >
                            <input
                              id="streamInput"
                              name="streamInput"
                              placeholder="Type a stream name"
                              type="text"
                              style={{ height: "35px" }}
                              maxLength="30"
                              onChange={this.streamInputChange}
                            />
                            <button
                              type="submit"
                              className="create-btn"
                              style={{
                                width: "80px",
                                hieght: "50px",
                                marginTop: "2px",
                              }}
                            >
                              Create
                            </button>
                          </div>
                          <span
                            style={{ color: "red" }}
                            hidden={this.state.streamNameError}
                          >
                            Stream already exists! Choose a different name
                          </span>
                        </form>
                        <hr />
                        <div>
                          <span className="offset-1">
                            Choose from existing:
                          </span>
                          {this.state.streams && this.state.streams.length > 0
                            ? this.state.streams.map(stream =>
                                this.getStreamHTML(stream.name)
                              )
                            : ""}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown
                      style={{
                        marginTop: "17px",
                        marginLeft: "8px",
                        float: "left",
                      }}
                    >
                      <Dropdown.Toggle as={CustomToggle2}></Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item className="stream-info">
                          Streams allow people to subscribe
                          <br />
                          and recieve an update whenever
                          <br />
                          you add to your stream.
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )
              ) : (
                <div>{this.state.pinStream ? this.state.pinStream : ""}</div>
              )}
            </div>
          </div>

          <textarea
            id="pinText"
            placeholder="Paste Content Here"
            rows="4"
            cols="44"
            className="pin-textarea"
            ref={this.pinText}
            defaultValue={
              this.props.editMode === false ? "" : this.props.storedPinText
            }
            onChange={this.handleTextChange}
          ></textarea>

          <div className="col-md-12 white-bg pull-left upload-file-div">
            <div className="upload-file-wrapper">
              <button
                className="attach-file-btn"
                disabled={this.state.uploading}
                onClick={
                  this.fileSelector.current
                    ? () => this.fileSelector.current.click()
                    : null
                }
              >
                Attach&nbsp;&nbsp;
                {/*<LoadingProgress
                  title={"Uploading.."}
                  active={this.state.uploading}
                  total={100}
                  current={this.state.progress}
                  hideProcessingRequest
                  hideTimeRemaining
                />*/}
                <FiPaperclip
                  className="clip"
                  size={20}
                  style={{ cursor: "pointer" }}
                  hidden={this.state.uploading}
                />
              </button>
              <input
                id="file-selector"
                type="file"
                onChange={this.handleFileClick}
                ref={this.fileSelector}
                hidden
              />
            </div>
          </div>

          {this.state.additionalPostHTML.map((val, index) => {
            let additional_html = ""

            if (val.includes("youtube.com/watch")) {
              additional_html = (
                <div
                  className="col text-center"
                  key={"addhtml" + index}
                  style={{ marginBottom: "20px" }}
                >
                  <ReactPlayer url={val} width="100%" />
                  <br />
                </div>
              )
            } else {
              additional_html = (
                <div className="col" key={"addhtml" + index}>
                  <ReactTinyLink
                    cardSize="small"
                    showGraphic={true}
                    maxLine={2}
                    minLine={1}
                    url={val}
                  />
                  <br />
                </div>
              )
            }

            return additional_html
          })}
          {this.state.fileurl ? (
            <div id="file-div" className="col">
              <FaTrashAlt
                id="deleteFile"
                onClick={this.deleteFile}
                className="offset-10"
              />
              <br />
              <br />
              <img
                id="file-image-div"
                src={this.state.fileurl}
                width="30%"
                className="offset-4"
                onError={this.imageError}
              ></img>
              <a
                id="file-download-div"
                target="_blank"
                href={this.state.fileurl}
                className="offset-4"
                download
                hidden
              >
                <div
                  className="col-6 offset-3 text-center"
                  style={{ border: "1px solid grey" }}
                >
                  <img src={FileIcon} width="25%"></img>
                  <br />
                  <span>Download File</span>
                </div>
                <br />
              </a>
            </div>
          ) : (
            ""
          )}

          <div className="row pin-time-row">
            <div className="col-md-6 col-6 pull-left">
              <h6 className="home-pin-title" style={{color: (this.state.user && this.state.user.pro)?'black':'grey'}}> Pin to current location</h6>
            </div>

            <div className="col-md-6 col-6 pull-left" style={{position:'relative'}}>
              <select id="pin-time" disabled={!(this.state.user && this.state.user.pro)}>
                <option value="-1">Disabled</option>
                <option value="1">1 Hour</option>
                <option value="6">6 Hours</option>
                <option value="24">24 Hours</option>
              </select>
              {
                !(this.state.user && this.state.user.pro) && 
                <div
                  style={{
                    position:'absolute',
                    width: 130,
                    height: 30,
                    backgroundColor:'transparent',
                    right: 0,
                  }}
                  onClick={()=>{this.gotoPro()}}
                >

                </div>
              }
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-6 pull-left">
              <input
                type="checkbox"
                id="require-password"
                disabled={!(this.state.user && this.state.user.pro)}
                onChange={() => this.toggle()}
              ></input>
              <label className="require-pwd-title" htmlFor="require-password" style={{color:(this.state.user && this.state.user.pro)?'black':'grey'}}
               >
                &nbsp;Require Password
              </label>
            </div>
            <div className="col-md-6 col-6 pull-left">
              <input
                id="password1"
                type="password"
                placeholder="Choose a Password"
                className="home-pwd-input"
                ref={this.password}
                disabled={!(this.state.user && this.state.user.pro)}
                hidden
              ></input>
            </div>

            <div className="col-md-12 pull-left">
              <button
                className="home-publish"
                id="pinPost"
                onClick={this.onPost}
                ref={this.pinPost}
              >
                {this.props.editMode === false ? "Publish" : "Update"}
              </button>
              <p
                style={{ textAlign: "center", color: "red" }}
                ref={this.showErrorMessage}
                hidden
              >
                {this.state.errorMessage}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default NewPinModal
